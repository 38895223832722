import React from 'react'
import axios from 'axios';
import { useState } from 'react';

const BACKEND_URL = process.env.NODE_ENV === "production" ? "https://friendly-allen-33c100.netlify.app" : "http://localhost:9000"

export default function ContactsForm() {
    // EMAIL
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ content, setContent ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');

    async function sendMail(e: any) {
        e.preventDefault()
        setLoading(true);
        setMessage('Envoi du message...');

        if (!(name.length > 0) || !(email.length > 0) || !(content.length > 0) ) {
        setLoading(false);
        return setMessage('Veuillez remplir tout les champs.')
        }

        try {
        const res = await axios.post(`${BACKEND_URL}/.netlify/functions/api`,{
            name,
            email,
            content
        });

        console.log(res.data)

        if (!res.data.success) {
            setLoading(false);
            return setMessage("Erreur lors de l'envoi de l'email, si cela persiste veuillez envoyer un email à audchtourou67@gmail.com directement.")
        }

            // reset fields
            setEmail("");
            setName("");
            setContent("");
            setMessage("J'ai bien reçu votre email, je vous recontacterai au plus vite !")
            return setLoading(false);
        }catch(err) {
            console.log(err)
            setLoading(false);
            return setMessage("Erreur lors de l'envoi de l'email, si cela persiste veuillez envoyer un email à audchtourou67@gmail.com directement.")
        }
    }

    return (
        <>
            <p>Contactez-moi via ce formulaire pour toutes demandes de tarifs !</p>
            <p>Concernant les cours au csc du neuhof: <a href="https://cscneuhof.eu/sport#pilates">https://cscneuhof.eu/sport#pilates</a>.</p>
            <div className="contacts-container" >
            <form onSubmit={sendMail} autoComplete="on">
                <input type="text" name="name" required id="name" placeholder="Nom" onChange={(e) => setName(e.target.value)} value={name} disabled={loading} />
                <input type="text" name="email" required id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} disabled={loading} />
                <textarea name="content" id="content" required cols={30} rows={10} placeholder="Contenu" onChange={(e) => setContent(e.target.value)} value={content} disabled={loading} ></textarea>
                <button className="button-primary" type="submit">
                ENVOYER
                </button>
                {
                message.length > 0 && <p>{message}</p>
                }
            </form>
            <div className="subinfo-container">
                <p><span>Email:</span> <a href="mailto:audchtourou67@gmail.com">audchtourou67@gmail.com</a></p>
            </div>
        </div>
        </>
    )
}
