import React, { createRef, useEffect } from 'react';
import Header from './components/header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import MethodePilates from "./screens/methode"
import smoothscroll from 'smoothscroll-polyfill';

import ContactsForm from './components/contacts';
import OneHealth from './screens/one-health';

interface IRefObject {
  profRef: React.RefObject<HTMLElement>;
  priceRef: React.RefObject<HTMLElement>;
  contactsRef: React.RefObject<HTMLElement>;
}

function App() {
  smoothscroll.polyfill();

  const allRef: IRefObject = {
    profRef : createRef<HTMLElement>(),
    priceRef : createRef<HTMLElement>(),
    contactsRef: createRef<HTMLElement>()
  }
  
  function ScrollToTop({ children }: { children : any }) {
    const location = useLocation();
    useEffect(() => {
      // @ts-ignore
      const value: string | undefined = location.state?.value
      if (value) {
        try  {
          // @ts-ignore
          allRef[value].current?.scrollIntoView({ behavior : "smooth" });
        }catch(err) {
          return;
        }
      }else{
        // window.scrollTo(0,0);
      }
      // @ts-ignore
    }, [ location.pathname, location.state?.value ]);
  
    return children;
  }

  function scrollToContact() {
    return allRef["contactsRef"].current?.scrollIntoView({ behavior : "smooth" });
  }

  return (
    <Router>
      <div className="App">
        <Header allRef={allRef} />
        <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            {/* PICTURE */}
            <section className="picture-container" >
                <h1>Stras & Pilates</h1>
            </section>

            {/* ACTUALITES */}
            <section className="news">
              <div className="n-content">
               
                <div className="flex-content">
                  <div> <h2 className="subtitle" >ACTUALITES: Pilates - AB-DOS-Stretch</h2>
                    <p className='bold italic'>❗ Retrouvez moi dans l'annuaire de Gasquet: <a href="https://annuaire.degasquet.com">annuaire.degasquet.com</a></p>
                    {/* <p className="bold" style={{ textDecoration: "underline"}}>Où me retouver dès septembre 2022:</p> */}
                    <nav style={{ marginLeft: "1rem", marginBottom: ".7rem"}}>
                      <p style={{ margin: 0}}>&#10144; CSC Neuhof: </p>
                      <li style={{ marginLeft: "1rem"}}>Jeudi 12h30: <a href="https://goo.gl/maps/QN1zny8q9N2j6iEFA" target="_blank" rel="noreferrer">Espace Ziegel</a></li>
                      <li style={{ marginLeft: "1rem"}}>Samedi 9h45 et 11h00: <a href="https://goo.gl/maps/7KtWFqimm2xn1FjE7" target="_blank" rel="noreferrer">Gymnase du collège Stockfeld</a></li>
                    </nav>
                    
                    <p className="italic bold cursor" onClick={() => scrollToContact()}>&#128073; Contactez-moi rapidement pour plus d'information</p>
                  </div>
                  <div className='pic-container'>
                    <img src="./pictures/affiche2024.jpg" alt="affiche" />
                  </div>
                </div>
              </div>
            </section>
            <hr className="news-separator"/>

            {/* LES BIENFAITS DE LA METHODE PILATES */}
            <section>
              <h2 className="subtitle">LES BIENFAITS DE LA METHODE PILATES</h2>
              <p>Accessible à tous les niveaux et à tous les âges, la méthode Pilates est une gymnastique douce de renforcement des muscles profonds qui vous apportera de réels bénéfices. </p>
              <nav style={{ marginBottom: "2rem" }}>
                <ul>
                    <li>Améliore la posture et l'équilibre.</li>
                    <li>Renforcement de tous les muscles profonds stabilisateurs.</li>
                    <li>Atténue les douleurs articulaires ou dorsales.</li>
                    <li>Développe la coordination.</li>
                    <li>Sculpte la silhouette.</li>
                    <li>Développe la capacité respiratoire et aide à trouver son rythme.</li>
                    <li>Allonge les muscles et entretient la souplesse et la flexibilité.</li>
                    <li>Déstresse et relaxe.</li>
                </ul>
              </nav>
              
              <div className="method-split">
                  <div>
                  <h3 >LE PILATES AU TAPIS</h3>
                  <p>N’oubliez pas votre tapis, la séance se déroule essentiellement au sol et peut être agrémentée par la sangle, l’élastique, la balle paille qui vous apporteront plus de diversité et de possibilité d’adaptation.</p>
                  {/* <p>Petits matériels non fournis.</p> */}
                  </div>
              </div>
              
              {/* <div className="method-split">
                  <div>
                      <h3 className="subtitle" >AUTRE SEANCES : BARRE PILATES ET GROS BALLON</h3>
                      <p>Dans l’esprit de la danse classique, c’est le Pilates en version debout, la barre est là pour vous accompagner dans la recherche de l'équilibre et du maintien du placement.</p>
                      <p>Le gros ballon vous permet d'accéder aux mouvements en position assise spécialement adapté aux séniors et aux femmes enceintes.</p>
                  </div>
              </div> */}
              <div className="f-center">
                <Link className="button-primary align-center" to="/methode-pilates" style={{ marginTop: "2rem"}}>
                  Qu'est ce que la méthode Pilates...
                </Link>
              </div>
            </section>
            <section ref={allRef.profRef} className="info-prof gasquet">
              <h2 className="subtitle">LA METHODE DE GASQUET</h2>
              <div className="text-container">
                <p className='align-left'>Je vous transmettrai les principes A.P.O.R® dans tous mes cours.</p>

<p>L'Approche Posturo Respiratoire s'articule autour de la respiration, des étirements, et du périnée avec la protection du dos et des abdominaux. Vous pouvez utiliser cette approche pour votre préparation à l'accouchement mais également après votre accouchement.</p>

<p>J'ai choisi d'appliquer la méthode dans mes séances de Pilates Yoga, pour cela je suis formée régulièrement par l'institut De Gasquet et figure sur l'annuaire De Gasquet.</p>
              </div>
              <img src="./pictures/degasquet.png" alt="method de gasquet"/>
            </section>
            {/* PROFESSEUR */}
            <section ref={allRef.profRef} className="info-prof">
              <h2 className="subtitle">PROFESSEUR</h2>
              <div className="text-container">
                <h3>AUDREY CHTOUROU</h3>
                <p>Diplômée CQP ALS Activités gymniques d’entretien et d’expression, formation initiale danse classique, jazz, cabaret, certifiée en  Pilates et Yoga fondamental depuis 2017 et spécialisée séniors,  femmes enceintes, post thérapie et petit matériel. Formée méthode de Gasquet: Yoga sans dégât, abdominaux perfectionnement.
                </p>
              </div>
              <img src="./pictures/prof_1.jpg" alt="présentation du professeur"/>
            </section>
            
          
            <section>
              <p style={{ fontWeight: "bold" }}>Voici l'ensemble des salles mises à disposition</p>
              <iframe title="multiple location" src="https://www.google.com/maps/d/u/0/embed?mid=1M_CTnIeBJdiOW5J-eqKR1KQ4775gfMuc" width="640" height="480" ></iframe>
            </section>
            
            <section ref={allRef.contactsRef}>
                <h2 className="subtitle">CONTACT / TARIFS</h2>
                <ContactsForm />
            </section>
          </Route>
          <Route path="/methode-pilates" component={MethodePilates} />
          <Route path="/one-health" component={OneHealth} />
        </Switch>

        </ScrollToTop>
        <p id="copyright">© 2022 - Stras & Pilates - droits réservés</p>
      </div>
      
    </Router>
  );
}


export default App;
