import React, { useEffect } from 'react'

export default function MethodePilates() {
    useEffect(() => {
      window.scrollTo(0,0);
    },[])
    return (
        <main className="method-details">
            <section className="method-header">
                <h1>Qu'est ce que la méthode Pilates ?</h1>
            </section>
            <section>
                <h2 className="subtitle" >PRINCIPES FONDAMENTAUX</h2>
                <p>L’idée inventée par Joseph Pilates est simple mais profonde.
                  Le Pilates est une méthode qui va de l’intérieur vers l’extérieur, à la différence des autres types
                  d’exercices et c’est ce qui le rend efficace. </p>
                <div className="method-split" style={{ marginTop: "4rem", marginBottom: "4rem"}}>
                <img src="./pictures/diagramme.png" alt="diagramme pic" />
                  <p style={{ textAlign: "justify" }}>Il nous faut commencer par renforcer les muscles de ce qu’on appelle « core », « centre », « powerhouse ».
                  Ces muscles stabilisateurs permettent de placer et de conserver le tronc dans la position optimale, tandis 
                  que les muscles du mouvement font leur travail.
                  La notion de muscles du centre réduit quelques fois le Pilates à une énième méthode pour aplatir le ventre 
                  avant les vacances d’été.
                  {`\n`}
                  {`\n`}
                  Le Pilates fait plus que ça, c’est un exercice de tout le corps.
                  Il développe tous les muscles et le fait de façon harmonieuse.
                  Aucun groupe musculaire n’est développé au détriment du groupe opposé (quadriceps et ischio­jambiers par 
                  exemple).
                  {`\n`}
                  {`\n`}
                  Ce développement harmonieux favorise symétrie et équilibre, tout en réduisant la pression sur les articulations.
                  Au lieu de faire travailler un seul muscle de manière répétitive, chaque exercice vise à faire travailler le 
                  corps dans son ensemble.
                  </p>
                </div>
                  
                  <div className="method-split" style={{ alignItems: "center" }}>
                  <p style={{ marginRight: "4rem", textAlign: "justify"}}>
                      Joseph Hubertus Pilates a créé une méthode révolutionnaire en étant persuadé que le travail du corps et de l'esprit était la base dans la recherche d'un entraînement de qualité aux bienfaits extraordinaires. 
                      Il parlait de six principes traditionnels qui étaient la concentration, le contrôle, le centrage, la fluidité du mouvement, la précision et la respiration. Reprenant ces principes, nous en avons ajouté deux qui nous semblaient essentiels, à savoir
                      l'isolation et la fréquence. Dites-vous bien que tous ces principes sont liés de très près les uns avec les autres, ne les dissociez pas, mais au contraire combinez-les pour leur donner cette synergie qui vous permettra de comprendre cette méthode et de prendre du recul sur la connaissance de votre corps.
                  </p>

                    <img src="./pictures/example1.jpeg" alt="example pic" />
                  </div>
            </section>
        </main>
    )
}
