import React from 'react'

export default function HoursComponent() {
    return (
        <div className="hours-container">
            <p><span>LUNDI &#8594;</span> 12h30 - Pilates</p>
            <hr />
            <p><span>VENDREDI &#8594;</span>12h30 - Pilates</p>
        </div>
    )
}
