import React, { useState } from 'react'
import "../../index.scss"
import {
    FiMenu
} from "react-icons/fi"
import {
    IoMdClose
} from "react-icons/io"
import {
    Link,
    useHistory
} from 'react-router-dom'

export default function Header({ allRef }: { allRef: any }) {

    const history = useHistory();

    function scroll(v: string){
        if (history.location.pathname === "/") {
            allRef[v].current?.scrollIntoView({ behavior : "smooth" });
        }else{
            history.push('/', {
                value: v
            });
        }
    }

    // burger
    const [ open, setOpen ] = useState(false);

    function handleMenu(scrollTo: string) {
        setOpen(!open);
        return scroll(scrollTo);
    }

    return (
        <header >
            <div className="header-container">
                <Link to="/">
                    <img src="/logo_small.png" alt="logo strasetpilates" id="logo" />
                </Link>
                <nav >
                    <li onClick={() => scroll("profRef")}>PROFESSEUR</li>
                    {/* <li onClick={() => scroll("priceRef")}>SEANCES ET TARIFS</li> */}
                    <li><Link to="/methode-pilates">LA METHODE PILATES</Link></li>
                    <li onClick={() => scroll("contactsRef")}>CONTACT/TARIFS</li>
                </nav>
                <button className="mb-container" onClick={() => setOpen(true)}>
                    <FiMenu className="mb-button" />
                </button>
            </div>

            {/* mobile navigation menu */}
            <div className="mobile-nav" style={{ display: open ? "inline" : "none", position: "fixed" }}>
                <button onClick={() => setOpen(false)}>
                    <IoMdClose className="mb-button" />
                </button>
                <div className="nav-container">
                    <li onClick={() => handleMenu("profRef")}>PROFESSEUR</li>
                    {/* <li onClick={() => handleMenu("priceRef")}>SEANCES ET TARIFS</li> */}
                    <li><Link to="/methode-pilates" onClick={() => setOpen(false)}>LA METHODE PILATES</Link></li>
                    <li onClick={() => handleMenu("contactsRef")}>CONTACT/TARIFS</li>
                </div>
            </div>
        </header>
    )
}
