import React from 'react'
import HoursComponent from '../components/hours'

export default function OneHealth() {
    return (
        <main className="one-health">
            {/* SEANCES ET TARIFS - ONE HEALTH */}
            <section >
              <h2 className="subtitle" >ONE HEALTH - SEANCES ET TARIFS</h2>
              <HoursComponent />
              <p>TARIFS annuels 2021/22 séances de 55 min sur 34 semaines. </p>

              <div className="tarifs-container">
                <div className="box-content">
                  <div className="info-line">
                      <p>1 séance par semaine</p>
                      <p>340€</p>
                  </div>
                  <hr />
                  <div className="info-line">
                      <p>2 séances par semaine</p>
                      <p>544€</p>
                  </div>
                  <hr />
                  <div className="info-line">
                      <p>Carte pour 10 séances</p>
                      <p>120€</p>
                  </div>
                </div>
                <p>REGLEMENT: chèque bancaire, espèces, facilité de paiement en 3 chèques encaissés en début de trimestre. Séance
            découverte offerte. Séances assurées hors vacances scolaires et sur zoom si nécessaire.</p>
              </div>
              <p style={{ fontWeight: "bold" }}>La nouvelle salle de cours sera communiqué ultérieurement.</p>
              <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d84484.10413711461!2d7.692054311575798!3d48.569092311360436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c8495e18b2c1%3A0x971a483118e7241f!2sStrasbourg!5e0!3m2!1sfr!2sfr!4v1627375974465!5m2!1sfr!2sfr" height={450} style={{ border: 0 }} allowFullScreen loading="lazy"></iframe>
            </section>
        </main>
    )
}
